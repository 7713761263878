<template>
  <v-container
    pa-0
    ma-0
    fluid
    fill-height
  >
    <v-layout
      fill-height
      pa-0
      ma-0
      row
    >
      <v-flex
        id="zd-toolbar"
        align="center"
      >
        <v-toolbar
          id="zd-toolbar"
          class="blue-grey lighten-4 tw-ml-0 tw-fixed"
          width="100%"
        >
          <v-btn
            icon
            dark
            rounded
            small
            text
            class="mt-0 mr-0"
            @click="$router.push({path: '/zendesk', query: {company: company }})"
          >
            <v-icon
              dark
              large
              class="font-weight-bold blue-grey--text pt-2 text--darken-2 "
            >
              mdi-arrow-left-circle
            </v-icon>
          </v-btn>

          <v-toolbar-title
            class="ml-0 pb-1 pl-0 font-weight-bold blue-grey--text text--darken-2"
          >
            Ticket #{{ ticket_id }}
          </v-toolbar-title>

          <v-spacer />
          <v-layout
            row
            align-center
          >
            <v-flex
              text-xs-right
              align-center
            />
            <v-flex
              shrink
              text-xs-right
            >
              <v-chip
                :class="colorStatus(status)"
                small
              >
                <span
                  style="width:50px; text-align:center"
                  class="white--text font-weight-medium text-capitalize"
                >{{ status }}
                </span>
              </v-chip>
            </v-flex>
          </v-layout>
        </v-toolbar>
        <v-container
          v-if="dialog"
          pa-0
          fill-height
        >
          <v-layout
            align-center
            justify-center
            row
            fill-height
          >
            <v-flex
              shrink
              class="tw-pt-24"
            >
              <v-progress-circular
                :size="70"
                :width="7"
                color="blue-grey darken-2"
                indeterminate
              />
            </v-flex>
          </v-layout>
        </v-container>

        <v-layout
          row
          wrap
          class="tw-pt-20"
        >
          <v-container
            px-6
            pt-2
            fluid
          >
            <v-flex
              v-for="(comment, index) in ticket.comments"
              :key="index"
              :class="{ 'border-yellow': !comment.public && index > 0, 'border-normal' : comment.public }"
              class="overflow-hidden comment"
            >
              <v-container
                fluid
                :class="{ 'amber lighten-3': !comment.public && index > 0, 'blue-grey lighten-5' : comment.public }"
              >
                <v-layout>
                  <v-flex

                    class="blue-grey--text text--darken-3"
                  >
                    <span class="body-2 font-weight-bold"> {{ comment.author_name }} </span>  <span class="caption"> {{ formatListDate (comment.created_at) }}</span>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-sheet
                :class="{ 'amber lighten-4': !comment.public && index > 0 }"
                class="mx-0 px-4 pt-2 overflow-hidden"
              >
                <v-flex
                  xs12
                  class="overflow-hidden"
                >
                  <p v-html="comment.html_body" /> 
                </v-flex>
              </v-sheet>
            </v-flex>
          </v-container>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data () {
    return {
      ticket: '',
      dialog: true
    }
  },
  computed: {
    ticket_id () {
      return this.$route.params.id
    },
    company () {
      return this.$route.params.company
    },
    date () {
      return this.$route.params.date
    },
    status () {
      return this.$route.params.status
    }
  },
  created () {
    this.$http.get(this.$api_url + 'zendesk/view-ticket/' + this.ticket_id, {
      headers: {
        'OAuth-Token': this.$store.getters.token
      }
    })
      .then(response => {
        this.dialog = false
        this.ticket = response.data
      })
  },
  methods: {
    humanizeDate (date) {
      return this.$moment(date).fromNow()
    },
    formatDate (date) {
      return this.$moment(date).format('MMM Do, YYYY')
    },
    formatListDate (date) {
      return this.$moment(date).format('[at] HH:MM[h] [GMT] (Z) [on] dddd, MMM Do YYYY ')
    },
    colorStatus (status) {
      switch (status) {
        case 'closed':
          return 'blue-grey darken-2'
        case 'solved':
          return 'blue-grey darken-2'
        case 'open':
          return 'red'
        case 'new':
          return 'yellow darken-3'
        case 'pending':
          return 'light-blue darken-4'
      }
    }
  }
}
</script>

<style>
    #core-view{
        height: 100%;
    }
    #zd-toolbar header .v-toolbar__content {
      margin-left: 0px !important;
    }
    .comment {
        margin: 4px;
        border-radius: 5px;

    }
    .border-yellow {
        border: 1px solid rgb(247, 210, 90);
    }
    .border-normal {
        border: 1px solid rgb(211, 211, 211);
    }
    .comment .v-sheet {
        min-height: 100px;
    }
</style>
